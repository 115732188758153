const toggleIcon = () => {
//	const win_width = window.innerWidth;
//	if(win_width < 769){
		if(document.getElementById('topmenuicon').textContent === '☰'){
			document.getElementById('topmenuicon').textContent = '×';
		} else {
			document.getElementById('topmenuicon').textContent = '☰';
		}
//	}
}
document.addEventListener('DOMContentLoaded',()=>{
	document.getElementsByClassName('topMenuIcon')[0].addEventListener('click', () => {
		console.log('Click Icon')
		document.getElementsByClassName('topMenuIcon')[0].classList.toggle('active');
		document.getElementsByClassName('header_menu')[0].classList.toggle('active');
		document.body.classList.toggle('lock');
		toggleIcon();
		return false;
	});

	const headerLinks = document.querySelectorAll('.header_link');
	if(headerLinks.length > 0){
		headerLinks.forEach(e => {
			if(document.getElementsByClassName('topMenuIcon').length > 0){		
				e.addEventListener('click', (eve) => {
					const win_width = window.innerWidth;
					if(win_width < 769){
						if(document.getElementsByClassName('topMenuIcon')[0].classList.contains('active')){
							document.getElementsByClassName('topMenuIcon')[0].classList.remove('active');
							document.getElementsByClassName('header_menu')[0].classList.remove('active');
							document.body.classList.remove('lock');
						}
						toggleIcon();
					}
					//eve.preventDefault();
				});
			}
		});
	}
});